<template>
  <div v-if="!isLoading">
    <HeaderPanel
      title="REDEEM Analysis"
      :filter="filter"
      @sidebar="sidebarFilter"
      :hasDropdown="false"
      :hasSearch="false"
      :hasExport="true"
      @export="exportCustomer"
    />
    <p class="my-2 f-16">
      Total Customer : {{ stat.totalCustomer }} ({{
        stat.totalCustomerPercent.toFixed(2)
      }}%)
    </p>
    <p class="my-2 f-16">
      {{
        filter.LastType == 0
          ? `${$moment(filter.DateFrom).format("DD MMM YYYY")} - ${$moment(
              filter.DateTo
            ).format("DD MMM YYYY")}`
          : `${$displayFilterDate(filter.LastType)}`
      }}
    </p>
    <StatPanel :column="statColumn" :data="stat" :isLoading="statLoading" />

    <div class="chart-bg">
      <b-container class="pt-3">
        <b-row class="chart-gap">
          <b-col cols="12">
            <b-card class="shadow-sm border-none text-black chart-rounded">
              <div class="d-flex justify-content-between mb-3">
                <div class="chart-title-name">Redeem By Date</div>
                <div class="d-flex" style="column-gap: 5px">
                  <div :class="`f-14 underline cursor-pointer`">
                    <span
                      class="text-highlight"
                      @click="exportExcel('1', 'redeem-analysis')"
                      >Export</span
                    >
                  </div>
                </div>
              </div>

              <Chart
                id="redeem-date-chart"
                :chartData="chartTop10"
                text="Number Of Customer"
                class="mt-auto"
                :label="chartTop10Label"
                :hideLegend="false"
                v-if="!isChartLoading"
                :customTooltipLabel="customLabel"
                type="bar"
              />
              <!-- :maintain="true" -->
              <ChartLoading
                :id="'loading-redeem-date-chart'"
                type="bar"
                v-else
              />
            </b-card>
          </b-col>
          <b-col sm="6" cols="12">
            <b-card class="shadow-sm border-none text-black chart-rounded">
              <div class="d-flex justify-content-between">
                <div class="chart-title-name">Redeem By Member Tier</div>
                <div class="d-flex" style="column-gap: 5px">
                  <div :class="`f-14 underline cursor-pointer`">
                    <span
                      class="text-highlight"
                      @click="exportExcel('4', 'redeem-chart-by-membertier')"
                      >Export</span
                    >
                  </div>
                </div>
              </div>

              <Chart
                id="member-tier"
                :chartData="chartData"
                text="Number Of Customer"
                class="mt-auto"
                :label="chartDataLabel"
                hideLegend
                v-if="!isChartLoading2"
                type="bar"
              />
              <ChartLoading :id="'loading-member-tier'" type="bar" v-else />
            </b-card>
          </b-col>
          <b-col sm="6" cols="12">
            <b-card class="shadow-sm border-none text-black chart-rounded">
              <div class="d-flex justify-content-between">
                <div class="chart-title-name">Redeem By Branch</div>
                <div class="d-flex" style="column-gap: 5px">
                  <div :class="`f-14 underline cursor-pointer`">
                    <span
                      class="text-highlight"
                      @click="exportExcel('5', 'redeem-chart-by-branch')"
                    >
                      Export
                    </span>
                  </div>
                </div>
              </div>
              <Chart
                id="by-branch"
                :chartData="chartData2"
                text="Number Of Customer"
                class="mt-auto"
                :label="chartDataLabel2"
                hideLegend
                v-if="!isChartLoading3"
                type="bar"
              /><ChartLoading :id="'loading-by-branch'" type="bar" v-else />
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card class="shadow-sm border-none text-black chart-rounded">
              <div class="d-flex justify-content-between">
                <div class="chart-title-name">Completed Redeem</div>
                <div class="d-flex align-items-center" style="column-gap: 5px">
                  <b-form-input
                    type="text"
                    id="header-search-bar"
                    class="search-bar mb-2"
                    @keyup.enter="handleSearch"
                    placeholder="Search Redeem Name"
                    v-model.trim="filter.search"
                    style="width: 300px"
                  >
                  </b-form-input>
                  <div :class="`f-14 underline cursor-pointer`">
                    <span
                      class="text-highlight mx-2"
                      @click="exportExcel('3', 'completed-redeem-detail')"
                      >Export Detail
                    </span>
                  </div>
                  <div :class="`f-14 underline cursor-pointer`">
                    <span
                      class="text-highlight"
                      @click="exportExcel('2', 'completed-redeem')"
                      >Export</span
                    >
                  </div>
                </div>
              </div>
              <b-table
                fixed
                striped
                hover
                :fields="fields"
                :items="items"
                :busy="isBusy"
                show-empty
                empty-text="No matching records found"
                foot-clone
                no-footer-sorting
                @sort-changed="sortingTable"
                :sort-by.sync="filterTable.sortBy"
                no-local-sorting
                :sort-desc.sync="filterTable.sortDesc"
              >
                <template #cell(numOfRedeem)="{ item }">
                  {{ item.numOfRedeem | numeral("0,0") }}
                  ({{ item.numOfRedeemPercent | numeral("0.00") }}%)
                </template>
                <template v-slot:foot()="data">
                  <span v-if="subTotal[data.field.key]" class="text-center">
                    {{ subTotal[data.field.key] | numeral("0,0.00") }}
                  </span>
                  <span v-else-if="data.field.key == 'numOfRedeem'">
                    {{ subTotal | numeral("0,0") }}
                  </span>

                  <span v-else>
                    <div class="text-left px-2" v-if="data.field.text">
                      {{ data.field.text }}
                    </div>
                  </span>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
              <Pagination
                @handleChangeTake="handleChangeTake"
                @pagination="pagination"
                :filter="filterTable"
                :rows="rows"
                :limit="2"
              />
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card class="shadow-sm border-none text-black chart-rounded">
              <div class="d-flex justify-content-between">
                <div class="chart-title-name">Redeem By Branch</div>
                <div class="d-flex align-items-center" style="column-gap: 5px">
                  <b-form-input
                    type="text"
                    id="header-search-bar"
                    class="search-bar mb-2"
                    @keyup.enter="handleSearch2"
                    placeholder="Search Branch Name"
                    v-model.trim="filterTable2.search"
                    style="width: 300px"
                  >
                  </b-form-input>

                  <div :class="`f-14 underline cursor-pointer mx-2`">
                    <span
                      class="text-highlight"
                      @click="exportExcel('6', 'redeem-by-branch')"
                      >Export</span
                    >
                  </div>
                </div>
              </div>
              <b-table
                fixed
                striped
                hover
                :fields="fields2"
                :items="items2"
                :busy="isBusy2"
                show-empty
                empty-text="No matching records found"
                no-footer-sorting
                @sort-changed="sortingTable2"
                :sort-by.sync="filterTable2.sortBy"
                no-local-sorting
                :sort-desc.sync="filterTable2.sortDesc"
              >
                <template #cell(numberOfItem)="{ item }">
                  {{ item.numberOfItem | numeral("0,0") }} ({{
                    item.percentNumberOfItem
                  }}%)
                </template>
                <template #cell(totalPointUsed)="{ item }">
                  {{ item.totalPointUsed | numeral("0,0") }}
                </template>
                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
              <Pagination
                @handleChangeTake="handleChangeTake2"
                @pagination="pagination2"
                :filter="filterTable2"
                :rows="rows2"
                :limit="2"
              /> </b-card
          ></b-col>
          <b-col cols="12"
            ><b-card class="shadow-sm border-none text-black chart-rounded">
              <div class="d-flex justify-content-between">
                <div class="chart-title-name">Redeem By Customer</div>
                <div class="d-flex align-items-center" style="column-gap: 5px">
                  <b-form-input
                    type="text"
                    id="header-search-bar"
                    class="search-bar mb-2"
                    @keyup.enter="handleSearch3"
                    placeholder="Search Customer Name"
                    v-model.trim="filterTable3.search"
                    style="width: 300px"
                  >
                  </b-form-input>

                  <div :class="`f-14 underline cursor-pointer mx-2`">
                    <span
                      class="text-highlight"
                      @click="exportExcel('7', 'redeem-by-customer')"
                      >Export</span
                    >
                  </div>
                </div>
              </div>
              <b-table
                fixed
                striped
                hover
                :fields="fields3"
                :items="items3"
                :busy="isBusy3"
                show-empty
                empty-text="No matching records found"
                no-footer-sorting
                @sort-changed="sortingTable3"
                :sort-by.sync="filterTable3.sortBy"
                no-local-sorting
                :sort-desc.sync="filterTable3.sortDesc"
              >
                <template v-slot:cell(telephone)="data">
                  <div>{{ convertTelePhone(data.item.telephone) }}</div>
                </template>
                <template #cell(numberOfItem)="{ item }">
                  {{ item.numberOfItem | numeral("0,0") }}
                </template>
                <template #cell(totalPointUsed)="{ item }">
                  {{ item.totalPointUsed | numeral("0,0") }}
                </template>
                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
              <Pagination
                @handleChangeTake="handleChangeTake3"
                @pagination="pagination3"
                :filter="filterTable3"
                :rows="rows3"
                :limit="2"
              /> </b-card
          ></b-col> </b-row
      ></b-container>
    </div>

    <SideBarFilter
      ref="sidebarFilter"
      :filter="filter"
      :placeholder="'Search Name'"
      @clearFilter="clearFilter"
      @searchAll="filterActions"
      :hideStatusFilter="false"
      :hideSearchBar="true"
    >
      <template v-slot:filter-option>
        <FilterCustomDate
          customText="Redeem Date"
          id="11"
          :from="filter.DateFrom"
          :to="filter.DateTo"
          :type="filter.LastType"
          @update:type="(value) => (filter.LastType = value)"
          @update:from="(value) => (filter.DateFrom = value)"
          @update:to="(value) => (filter.DateTo = value)"
        />

        <div class="my-3">
          <MultiSelect
            used="tier"
            textFloat="Member tier"
            :options="options.memberTiers"
            textField="memberTierName"
            valueField="id"
            placeholder="Select Member Tier"
            v-model="filter.MemberTier"
            :value="filter.MemberTier"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            textFloat="Redeem"
            :options="options.redeem"
            textField="name"
            valueField="id"
            placeholder="Select Redeem"
            v-model="filter.RedeemName"
            :value="filter.RedeemName"
          />
        </div>

        <div class="mb-3">
          <MultiSelect
            textFloat="Redeem Item"
            :options="options.redeemItems"
            textField="name"
            valueField="id"
            placeholder="Select Redeem Item"
            v-model="filter.RedeemItem"
            :value="filter.RedeemItem"
          />
        </div>
        <check-box-anonymous
          :value="filter.allow_anonymous"
          @change="(val) => (filter.allow_anonymous = val)"
          class="mb-3"
        />
      </template>
    </SideBarFilter>
    <ModalInputEmail
      ref="ModalInputEmail"
      :form="filterExportFile"
      @closeModal="clearExportFilter"
      @changeEmail="(val) => (filterExportFile.email = val)"
      @submit="exportCustomer"
    />
  </div>
</template>

<script>
import ModalInputEmail from "@/components/customer/ModalInputEmail";
import Chart from "chart";
import ChartLoading from "@/components/loading/ChartLoading";
function getRandomColor() {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return "rgb(" + r + "," + g + "," + b + ")";
}

const moment = require("moment");

export default {
  components: {
    Chart,
    ChartLoading,
    ModalInputEmail,
  },
  data() {
    return {
      isBusy: true,
      isBusy2: true,
      isBusy3: true,
      isProductBusy: false,
      isChartLoading: true,
      fields: [
        {
          key: "redeemName",
          label: "Redeem Name",
          sortable: true,
          text: "Total",
        },
        { key: "redeemItemName", label: "Redeem Item Name", sortable: true },
        { key: "typeOfRedeem", label: "Type of Redeem", sortable: true },
        {
          key: "numOfRedeem",
          label: "Number of Redeem % of User Compare by Total User",
          sortable: true,
        },
      ],
      fields2: [
        {
          key: "branchName",
          label: "Branch Name",
          sortable: true,
          text: "Total",
        },
        { key: "numberOfItem", label: "Number Of Item (%)", sortable: true },
        {
          key: "numberOfRedeemUser",
          label: "Number Of Redeem User",
          sortable: true,
        },
        {
          key: "totalPointUsed",
          label: "Total Point Redeem",
          sortable: true,
        },
      ],
      fields3: [
        {
          key: "customerName",
          label: "Customer Name",
          sortable: true,
          text: "Total",
        },
        { key: "memberId", label: "Member ID.", sortable: true },
        {
          key: "telephone",
          label: "Telephone",
          sortable: true,
        },
        {
          key: "totalPointUsed",
          label: "Total Point Redeem",
          sortable: true,
        },
        {
          key: "numberOfItem",
          label: "Number of item",
          sortable: true,
        },
        {
          key: "numberOfRedeem",
          label: "Number of Redeem",
          sortable: true,
        },
      ],
      statColumn: [
        {
          name: "Total Redeem",
          key: "totalRedeem",
          isPercent: false,
          isDecimal: false,
        },

        {
          name: "Total Customer",
          key: "totalCustomer",
          isPercent: false,
          isDecimal: false,
        },
      ],
      statLoading: true,
      stat: {
        totalCustomer: 0,
        totalCustomerPercent: 0,
        totalRedeem: 0,
        dateFrom: "2023-04-01T00:00:00",
        dateTo: "2023-05-30T00:00:00",
      },
      filterExportFile: {
        email: "",
      },
      filter: {
        DateFrom: this.$store.state.filter.from,
        DateTo: this.$store.state.filter.to,
        Search: "",
        MemberTier: this.$store.state.filter.tier,
        RedeemName: [],
        RedeemItem: [],
        Page: 1,
        GraphType: 2,
        take: 10,
        LastType: this.$store.state.filter.dateType,
        allow_anonymous: this.$store.state.filter.allow_anonymous,
      },

      filterTable: {
        page: 1,
        take: 10,
        search: "",
        SortColumnId: 0,
        SortType: true,
        sortBy: "",
        sortDesc: "",
      },
      filterTable2: {
        page: 1,
        take: 10,
        search: "",
        SortColumnId: 0,
        SortType: true,
        sortBy: "",
        sortDesc: "",
      },
      filterTable3: {
        page: 1,
        take: 10,
        search: "",
        SortColumnId: 0,
        SortType: true,
        sortBy: "",
        sortDesc: "",
      },
      loading: true,
      loading2: true,
      items: [],
      items2: [],
      items3: [],

      rows: 0,
      rows2: 0,
      rows3: 0,
      rowsProduct: 0,
      chartAgeData: [],
      chartAgeLabel: [],
      chartTop10: [],
      chartData: [],
      chartDataLabel: [],
      isChartLoading2: false,
      chartData2: [],
      chartDataLabel2: [],
      isChartLoading3: false,
      chartTop10Label: [],
      options: {
        memberTiers: [],
        redeem: [],
        redeemItems: [],

        dateType: [
          { text: "Last 7 Days", value: 1 },
          { text: "Last 30 Days", value: 2 },
          { text: "Last 365 Days", value: 3 },
          { text: "Custom", value: 0 },
        ],
        // product: [],
        // transactionDate: [],
      },
      subTotal: {},
    };
  },
  async created() {
    this.isLoading = true;
    this.getOptions();
    this.getData();
    this.isLoading = false;
  },
  methods: {
    async getData() {
      this.getCompletedRedeem();
      this.getRedeemBranch();
      this.getRedeemByCustomer();
      this.isChartLoading = true;
      this.isChartLoading2 = true;
      this.isChartLoading3 = true;
      const res = await this.$report.post(
        `/DashBoard/redeem/default`,
        this.filter
      );
      this.getStat(res.data.detail.result_analysis);
      this.getTotal(res.data.detail.result_completed_subtotal);
      this.getRedeemChart(res.data.detail.result_graphtop10);
      this.getRedeemChartMemberTier(res.data.detail.result_membertier);
      this.getRedeemChartBranch(res.data.detail.result_branch);
    },
    async getStat(data) {
      this.statLoading = true;
      var respData = data;
      if (!data) {
        const res = await this.$report.post(
          `/dashboard/RedeemAnalysis`,
          this.filter
        );
        respData = res.data.detail;
      }
      this.statLoading = false;
      this.stat = respData;
    },
    async getTotal(data) {
      var respData = data;
      if (!data) {
        const res = await this.$report.post(
          `/Dashboard/RedeemAnalysis/CompletedRedeem_Subtotal`,
          this.filter
        );
        respData = res.data.detail;
      }

      this.subTotal = respData;
    },

    async getRedeemChart(data) {
      var respData = await data;
      if (!data) {
        this.isChartLoading = true;
        const res = await this.$report.post(
          `/dashboard/RedeemAnalysis/Graph`,
          this.filter
        );
        respData = res.data.detail;
      }
      // if (this.filter.GraphType == 1) {
      this.chartTop10Label = respData.map((el) => el.redeem_name);
      // } else {
      //   this.chartTop10Label = respData.map((el) => el.group);
      // }
      let color = await this.$store.dispatch("getChartColor", 0);
      var data = [];

      var index = 0;
      for (const x of respData) {
        if (index == 0) {
          // var color = getRandomColor();
          data.push({
            label: "Number of Customer",
            type: "bar",
            data: [x.total],
            group: true,

            backgroundColor: color,
          });
        } else {
          console.log(data, index);
          data[0].data.push(x.total);
          // data[index + 1].data.push(0);
        }
        index++;
        // }
      }

      this.chartTop10 = data;

      this.isChartLoading = false;
    },
    async getRedeemChartMemberTier(data) {
      this.isChartLoading2 = true;
      var respData = await data;
      if (!data) {
        const res = await this.$report.post(
          `/dashboard/redeem/graph_membertier`,
          this.filter
        );
        respData = res.data.detail;
      }

      this.chartDataLabel = respData.map((el) => el.name);
      let color = await this.$store.dispatch("getChartColor", 0);

      var data = [
        {
          label: "Number Of Customer",
          type: "bar",
          data: respData.map((el) => el.totalUser),
          borderColor: color,
          backgroundColor: color,
          fill: false,
        },
      ];

      this.chartData = data;

      this.isChartLoading2 = false;
    },
    async getRedeemChartBranch(data) {
      this.isChartLoading3 = true;
      var respData = await data;
      if (!data) {
        const res = await this.$report.post(
          `/dashboard/redeem/graph_branch`,
          this.filter
        );
        respData = res.data.detail;
      }

      this.chartDataLabel2 = respData.map((el) => el.name);
      let color = await this.$store.dispatch("getChartColor", 0);

      var data = [
        {
          label: "Number Of Customer",
          type: "bar",
          data: respData.map((el) => el.totalUser),
          borderColor: color,
          backgroundColor: color,
          fill: false,
        },
      ];

      this.chartData2 = data;

      this.isChartLoading3 = false;
    },
    async getCompletedRedeem() {
      this.isBusy = true;
      let payLoad = { ...this.filter };
      payLoad.Page = this.filterTable.page;
      payLoad.take = this.filterTable.take;
      payLoad.SortType = this.filterTable.SortType;
      payLoad.SortColumnId = this.filterTable.SortColumnId;

      const res = await this.$report.post(
        `/dashboard/RedeemAnalysis/CompletedRedeem`,
        payLoad
      );

      this.items = res.data.detail.data;
      this.rows = res.data.detail.total;
      this.isBusy = false;
    },
    async getRedeemBranch() {
      this.isBusy2 = true;
      let payLoad = { ...this.filter };
      payLoad.Page = this.filterTable2.page;
      payLoad.take = this.filterTable2.take;
      payLoad.search = this.filterTable2.search;
      payLoad.SortType = this.filterTable2.SortType;
      payLoad.SortColumnId = this.filterTable2.SortColumnId;

      const res = await this.$report.post(
        `/dashboard/redeem/table_branch`,
        payLoad
      );

      this.items2 = res.data.detail.data;
      this.rows2 = res.data.detail.total;
      this.isBusy2 = false;
    },
    async getRedeemByCustomer() {
      this.isBusy3 = true;
      let payLoad = { ...this.filter };
      payLoad.Page = this.filterTable3.page;
      payLoad.take = this.filterTable3.take;
      payLoad.search = this.filterTable3.search;
      payLoad.SortType = this.filterTable3.SortType;
      payLoad.SortColumnId = this.filterTable3.SortColumnId;

      const res = await this.$report.post(
        `/dashboard/redeem/table_customer`,
        payLoad
      );

      this.items3 = res.data.detail.data;
      this.rows3 = res.data.detail.total;
      this.isBusy3 = false;
    },
    async getOptions() {
      // this.options.coupon = res.data.detail;
      this.getRedeemOption();
      this.getRedeemItemOption();
      this.getMemberTiersOption();
      // this.stat = res.data.detail;
    },
    async getMemberTiersOption() {
      const memberTier = await this.$report(`/RevenueReport/CustomFilter`);
      this.options.memberTiers = memberTier.data.memberTiers;
    },
    async getRedeemOption() {
      const redeem = await this.$report(`/dashboard/Filter/Redeem`);
      this.options.redeem = redeem.data.detail;
    },
    async getRedeemItemOption() {
      const RedeemItems = await this.$report(`/dashboard/Filter/RedeemItem`);
      this.options.redeemItems = RedeemItems.data.detail;
    },
    pagination(val) {
      this.filterTable.page = val;

      this.getCompletedRedeem();
    },
    pagination2(val) {
      this.filterTable2.page = val;

      this.getRedeemBranch();
    },

    handleChangeTake(val) {
      this.filterTable.page = 1;

      this.filterTable.take = val;
      this.getCompletedRedeem();
    },
    handleChangeTake2(val) {
      this.filterTable2.page = 1;

      this.filterTable2.take = val;
      this.getRedeemBranch();
    },
    handleChangeTake3(val) {
      this.filterTable3.page = 1;

      this.filterTable3.take = val;
      this.getRedeemByCustomer();
    },
    pagination3(val) {
      this.filterTable3.page = val;

      this.getRedeemByCustomer();
    },

    async exportExcel(mode, name) {
      try {
        this.filter.Mode = mode;

        this.$bus.$emit("showLoading");
        var res = null;
        if (mode == 1) {
          res = await this.$report.post(
            `/dashboard/redeem/top10redeem/report`,
            this.filter,
            {
              responseType: "blob",
            }
          );
        } else {
          res = await this.$report.post(
            `/dashboard/Export/RedeemAnalysis`,
            this.filter,
            {
              responseType: "blob",
            }
          );
        }
        let data = res;

        var fileURL = window.URL.createObjectURL(new Blob([data.data]));
        var fileLink = document.createElement("a");
        // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", name + `.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
        this.$bus.$emit("hideLoading");
      } catch (error) {
        console.error(error);
        this.errorAlert(error.message);
        this.$bus.$emit("hideLoading");
      }
    },
    filterActions() {
      this.getData();
    },
    clearFilter() {
      this.filter = {
        DateFrom: moment().subtract(1, "year").format("YYYY-MM-DD"),
        DateTo: moment().format("YYYY-MM-DD"),
        Search: "",
        MemberTier: [],
        RedeemName: [],
        RedeemItem: [],
        Page: 1,
        GraphType: 1,
        take: 10,
        LastType: 3,
        allow_anonymous: this.$store.state.filter.allow_anonymous,
      };
      this.filter.LastType = 2;
      this.$store.dispatch("clearFilter");
      this.$refs.sidebarFilter.hide();
      this.filterActions();
    },
    async exportCustomer() {
      try {
        this.$bus.$emit("showLoading");

        const res = await this.$report.post(
          `/DashBoard/Export/redeem_customer`,
          this.filter
        );
        if (res.status == 200) {
          if (res.data.result)
            this.confirmAlert({
              message: "Do you want to download the file now?",
              title: "Success !",
              icon: "success",
              confirmButtonText: "Go To Page",
              cancelButtonText: "Close",
            }).then((val) => {
              if (val.isConfirmed) {
                // this.deleteItem(id);
                const routeData = this.$router.resolve({
                  name: "Customer Report List",
                });
                window.open(routeData.href, "_blank");
              }
            });
          else this.errorAlert(res.data.message);
        } else {
          this.errorAlert(res.data.message);
        }
        this.$bus.$emit("hideLoading");
      } catch (error) {
        console.error(error);
        this.errorAlert(error.message);
        this.$bus.$emit("hideLoading");
      }
    },
    exportModal() {
      this.$refs.ModalInputEmail.show();
    },
    handleSearch() {
      this.getCompletedRedeem();
    },
    handleSearch2() {
      this.getRedeemBranch();
    },
    handleSearch3() {
      this.getRedeemByCustomer();
    },
    sidebarFilter() {
      this.$refs.sidebarFilter.show();
    },
    filterGraph(val) {
      this.filter.GraphType = val;
      this.getRedeemChart();
    },
    clearExportFilter() {
      this.filterExportFile = {
        email: "",
      };
    },
    sortingTable(ctx) {
      let index = this.fields.findIndex((el) => el.key == ctx.sortBy);
      index = index + 1;

      if (
        this.filterTable.SortType &&
        !ctx.sortDesc &&
        this.filterTable.SortColumnId == index
      ) {
        this.filterTable.SortColumnId = 0;
        this.filterTable.SortColumnId = 0;
        this.filterTable.sortBy = "";
        this.filterTable.sortDesc = "";
      } else {
        this.filterTable.SortColumnId = index;
        this.filterTable.SortType = ctx.sortDesc;
      }
      this.getCompletedRedeem();
    },
    sortingTable2(ctx) {
      let index = this.fields2.findIndex((el) => el.key == ctx.sortBy);
      index = index + 1;

      if (
        this.filterTable2.SortType &&
        !ctx.sortDesc &&
        this.filterTable2.SortColumnId == index
      ) {
        this.filterTable2.SortColumnId = 0;
        this.filterTable2.SortColumnId = 0;
        this.filterTable2.sortBy = "";
        this.filterTable2.sortDesc = "";
      } else {
        this.filterTable2.SortColumnId = index;
        this.filterTable2.SortType = ctx.sortDesc;
      }
      this.getRedeemBranch();
    },
    sortingTable3(ctx) {
      let index = this.fields3.findIndex((el) => el.key == ctx.sortBy);
      index = index + 1;

      if (
        this.filterTable3.SortType &&
        !ctx.sortDesc &&
        this.filterTable3.SortColumnId == index
      ) {
        this.filterTable3.SortColumnId = 0;
        this.filterTable3.SortColumnId = 0;
        this.filterTable3.sortBy = "";
        this.filterTable3.sortDesc = "";
      } else {
        this.filterTable3.SortColumnId = index;
        this.filterTable3.SortType = ctx.sortDesc;
      }
      this.getRedeemByCustomer();
    },
    getDataFilter() {
      return this.options.dateType.find(
        (el) => el.value == this.filter.LastType
      ).text;
    },
    customLabel(tooltipItem, data) {
      var datasetLabel =
        data.datasets[tooltipItem.datasetIndex].label || "Other";
      var value =
        tooltipItem.datasetIndex % 2 == 0 ? " (value)" : " (Used Value)";

      if (tooltipItem.datasetIndex % 2 == 0) {
        return datasetLabel + ": " + this.addCommas(tooltipItem.value);
      }
    },
  },
};
</script>

<style>
.text-highlight {
  color: var(--primary-color);
  font-weight: bold;
  opacity: 1 !important;
}
.text-filter {
  opacity: 0.5;
}
</style>
